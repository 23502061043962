import React from 'react'
import SpadeData from '../../components/portfolio/spade-data'
import SEO from '../../components/seo'

const SpadeDataPage = () => (
  <>
    <SEO title="Tabreezi Portfolio" />
    <SpadeData />
  </>
)

export default SpadeDataPage
