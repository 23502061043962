import React from 'react'
import styled from 'styled-components'
import ApiImage from './images/api'
import CodeImage from './images/code'
import ComputersImage from './images/computers'
import LogoImage from './images/logo'
import RobotFacingRight from '../images/robot-facing-right'
import RobotFacingLeft from '../images/robot-facing-left'

const text = {
  title: 'SPADE_',
  intro:
    ' identified a huge gap in financial technology in the Middle East. They were intensely curious on why the region was not seeing the emergence of great financial products...',
  sectionOneTitle: 'Build Amazing Fintech Apps',
  sectionOne:
    'We identified a major issue: banking data was inaccessible. We designed a product that would revolutionize the region, providing organized & real time data to financial applications.',
  sectionTwoTitle: 'Product / UX / Website & Development Architecture',
  sectionTwo:
    'The software development kit we designed connected banks, users, & fintech applications. The SDK allowed an apps users to access their financial data by connecting to their bank in the application. Developers could now build great niche financial products without the hassle of connecting to banking data. Users could now reap the benefit of great fintech apps modeled after Venmo, Robinhood, Clarity, & others.',
  sectionThreeTitle: 'Market Research & Competitor Analysis',
  sectionThree:
    'We studied the landscape, competitors in the space, met with key players & landed on revolutionary technology capable of igniting exponential growth in the Middle East.',
}

const Landing = () => {
  return (
    <PageContainer>
      <Background>
        <IntroContainer>
          <IntroImageContainer>
            <LogoImage />
          </IntroImageContainer>
          <IntroTextContainer>
            <IntroTitle>{text.title}</IntroTitle>
            <IntroDetail>
              <StyledLink href="https://www.spadedata.com" target="_blank">
                Spade
              </StyledLink>
              {text.intro}
            </IntroDetail>
          </IntroTextContainer>
        </IntroContainer>
      </Background>
      <Background color="white">
        <TestImageRight>
          <RobotImageContainerLeft>
            <RobotFacingLeft />
          </RobotImageContainerLeft>
        </TestImageRight>
        <Section>
          <TextContainer>{text.sectionOne}</TextContainer>
          <ImageContainer>
            <ComputersImage />
          </ImageContainer>
        </Section>
      </Background>
      <Section>
        <SectionTitle>{text.sectionTwoTitle}</SectionTitle>
        <TextContainer>{text.sectionTwo}</TextContainer>
        <ImageContainer>
          <ApiImage />
        </ImageContainer>
      </Section>
      <Background color="white">
        <TestImageRight>
          <RobotImageContainerRight>
            <RobotFacingRight />
          </RobotImageContainerRight>
        </TestImageRight>
        <Section>
          <SectionTitle>{text.sectionThreeTitle}</SectionTitle>
          <TextContainer>{text.sectionThree}</TextContainer>
          <ImageContainer>
            <CodeImage />
          </ImageContainer>
        </Section>
      </Background>
    </PageContainer>
  )
}

export default Landing

const TestImageRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100vw;
  margin: 0px auto;
  overflow: hidden;
`

const StyledLink = styled.a`
  color: #5f3fdc;
  :hover {
    color: white;
  }
`

const PageContainer = styled.div`
  text-align: center;
  background: white;
  color: black;
  font-family: 'Helvetica Neue';
  line-height: 1.5;
`

const Background = styled.div`
  background-color: ${props => props.color || 'black'};
  width: 100vw;
  position: relative;
`

const IntroContainer = styled.div`
  background: black;
  color: white;
  display: flex;
  padding: 20px 0px;
  max-width: 1200px;
  margin: 0 auto;

  @media screen and (min-width: 1200px) {
    padding: 50px 0px;
  }
`
const IntroImageContainer = styled.div`
  width: 30%;
  padding-left: 3%;
  padding: 20px 0px 20px 20px;
  margin: 0px auto;
`

const IntroTitle = styled.div`
  font-size: 36px;
  font-weight: 500;

  @media screen and (min-width: 375px) {
    font-size: calc(36px + (100 - 36) * ((100vw - 375px) / (1200 - 375)));
  }

  @media screen and (min-width: 1200px) {
    font-size: 100px;
  }
`

const IntroDetail = styled.div`
  font-size: 10px;
  padding-right: 10px;

  @media screen and (min-width: 375px) {
    font-size: calc(12px + (28 - 12) * ((100vw - 375px) / (1200 - 375)));
  }

  @media screen and (min-width: 1200px) {
    font-size: 28px;
  }
`

const IntroTextContainer = styled.div`
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 3%;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props => props.padding || '3%'};
  max-width: ${props => props.max || '1200px'};
  overflow: ${props => props.overflow || 'visible'};
  margin: 0 auto;
  position: relative;
  background: transparent;
`
const TextContainer = styled.div`
  font-size: 12px;
  color: #707070;
  width: 75%;
  margin-bottom: 20px;
  font-weight: 300;
  max-width: 800px;
  padding: 2% 0 3%;
  background-color: transparent;
  z-index: 1;

  @media screen and (min-width: 375px) {
    font-size: calc(12px + (32 - 12) * ((100vw - 375px) / (1200 - 375)));
  }

  @media screen and (min-width: 1200px) {
    font-size: 32px;
  }
`
const ImageContainer = styled.div`
  width: ${props => props.width || '85%'};
  margin: 0px auto;
`

const SectionTitle = styled.div`
  font-weight: bold;

  @media screen and (min-width: 375px) {
    font-size: calc(12px + (32 - 12) * ((100vw - 375px) / (1200 - 375)));
  }

  @media screen and (min-width: 1200px) {
    font-size: 32px;
  }
`

const RobotImageContainerRight = styled.div`
  position: absolute;
  width: 35%;
  top: 30%;
  left: -27%;
`

const RobotImageContainerLeft = styled.div`
  position: absolute;
  width: 35%;
  top: 8%;
  right: -25%;
`
